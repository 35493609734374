import {QCP_NAMES} from "./qcpNames";

/**
 * Gets a list of laboratories of a QCP
 * @param {string} qcpName
 * @returns {string[]} laboratories
 */
export const getLabs = (qcpName) => {
  let labsList;

  switch (process.env.ENVIRONMENT) {
    case "development":
      labsList = LOCAL_LABS;
      break;

    case "staging":
      labsList = STAGE_LABS;
      break;

    case "demo":
      labsList = DEMO_LABS;
      break;

    default:
      labsList = LABS;
  }

  return (
    labsList
      .filter((company) => !!company.qcps.find((qcp) => qcp === qcpName))
      .map((company) => company.name) ?? []
  );
};

const LABS = [
  {
    name: "HusteelLab",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_22_02_02_Rev_5, QCP_NAMES.HS_D_ITP_23_01_06_Rev_3
    ]
  },
  {
    name: "SumitomoLab",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6_MPQT,
      QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6
    ]
  },
  {
    name: "TenarisLab",
    qcps: [
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_7928_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5982_Rev_4_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5982_Rev_4
      QCP_NAMES.QCP_5987_Rev_2,
      QCP_NAMES.QCP_5987_Rev_2_FDPT,
      QCP_NAMES.QCP_5983_Rev_5,
      QCP_NAMES.QCP_5983_Rev_5_FDPT
    ]
  },
  {
    name: "Test Lab",
    qcps: [
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_QP_OST_HF_YGG_BUT_00068,
      QCP_NAMES.QCP_ITP_030_24_130786_YGG_BUT_00073,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT
    ]
  },
  {
    name: "Tremblay",
    qcps: [
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_QP_OST_HF_YGG_BUT_00068,
      QCP_NAMES.QCP_ITP_030_24_130786_YGG_BUT_00073,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_7928_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5982_Rev_4_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5982_Rev_4,
      QCP_NAMES.QCP_5987_Rev_2,
      QCP_NAMES.QCP_5987_Rev_2_FDPT,
      QCP_NAMES.QCP_5983_Rev_5,
      QCP_NAMES.QCP_5983_Rev_5_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_22_02_02_Rev_5,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_23_01_06_Rev_3,
      QCP_NAMES.ITP_BPM_22_20_Rev_6,
      QCP_NAMES.ITP_BPM_22_20_Rev_6_MPQT
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05_MPQT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6_MPQT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3_MPQT
    ]
  }
];

const DEMO_LABS = [
  {
    name: "Mechan Lab",
    qcps: [QCP_NAMES.FP_ITP_Rev_0]
  },
  {
    name: "Tenaris-Siderca",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6,
      QCP_NAMES.QCP_8263_Rev_6_MPQT_FDPT
    ]
  }
];

const STAGE_LABS = [
  {
    name: "Tremblay Inc.",
    qcps: [
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_QP_OST_HF_YGG_BUT_00068,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005,
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_7928_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5982_Rev_4_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5982_Rev_4,
      QCP_NAMES.QCP_5987_Rev_2,
      QCP_NAMES.QCP_5987_Rev_2_FDPT,
      QCP_NAMES.QCP_5983_Rev_5,
      QCP_NAMES.QCP_5983_Rev_5_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_22_02_02_Rev_5,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_23_01_06_Rev_3,
      QCP_NAMES.ITP_BPM_22_20_Rev_6,
      QCP_NAMES.ITP_BPM_22_20_Rev_6_MPQT
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05_MPQT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6_MPQT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3_MPQT
    ]
  }
];

const LOCAL_LABS = [
  {
    name: "Test Lab",
    qcps: [
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_QP_OST_HF_YGG_BUT_00068,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT
    ]
  },
  {
    name: "Laboratory Company",
    qcps: [
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3_FDPT,
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT
    ]
  }
];
