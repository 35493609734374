export const QCP_NAMES = {
  QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT:
    "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT",
  QCP_ITP_00923_124277_NOA_BUT_00013_HRB:
    "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB",
  QCP_QP_OST_HF_YGG_BUT_00068: "QCP QP-OST-HF (YGG-BUT-00068)",
  QCP_ITP_030_24_130786_YGG_BUT_00073: "QCP ITP 030/24-130786 (YGG-BUT-00073)",
  QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT:
    "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT",
  QCP_CPW_ITP_21_23_HFW_YGG_COP_00005: "QCP CPW-ITP-21-23-HFW (YGG-COP-00005)",
  QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT:
    "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT",
  QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064:
    "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064)",
  QCP_9348_YGG_TGS_00010_GL_Reelable: "QCP 9348 (YGG-TGS-00010 - GL Reelable)",
  QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT:
    "QCP 9348 (YGG-TGS-00010 - GL Reelable) - FDPT",
  QCP_10378_YGG_TGS_00045_GL_Pipe: "QCP 10378 (YGG-TGS-00045 - GL Pipe)",
  QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT:
    "QCP 10378 (YGG-TGS-00045 - GL Pipe) - FDPT",
  QCP_6916_Rev_3_FDPT: "QCP 6916 Rev. 3 (FDPT)",
  QCP_6916_Rev_3: "QCP 6916 Rev. 3",
  QCP_7928_Rev_2: "QCP 7928 Rev. 2",
  HS_D_ITP_22_02_02_Rev_5: "HS-D-ITP-22-02-02 Rev. 5",
  HS_D_ITP_23_01_06_Rev_3: "HS-D-ITP-23-01-06 Rev. 3",
  FP_ITP_Rev_0: "FP-ITP Rev. 0",
  FX_FBE_Coating_ITP_Rev_0: "FX-FBE-Coating-ITP Rev. 0",
  FX_Spool_ITP_Rev_0: "FX-Spool-ITP Rev. 0",
  MTIP_WA0058_4400025974_H06_44004_Rev_6_MPQT:
    "MTIP WA0058-4400025974-H06-44004 Rev. 6 (MPQT)",
  MTIP_WA0058_4400025974_H06_44004_Rev_6:
    "MTIP WA0058-4400025974-H06-44004 Rev. 6",
  QCP_8262_Rev_6: "QCP 8262 Rev. 6",
  QCP_8262_Rev_6_MPQT_FDPT: "QCP 8262 Rev. 6 MPQT/FDPT",
  QCP_8252_Rev_6: "QCP 8252 Rev. 6",
  QCP_8252_Rev_6_MPQT_FDPT: "QCP 8252 Rev. 6 MPQT/FDPT",
  QCP_8251_Rev_6: "QCP 8251 Rev. 6",
  QCP_8251_Rev_6_MPQT_FDPT: "QCP 8251 Rev. 6 MPQT/FDPT",
  QCP_8263_Rev_6: "QCP 8263 Rev. 6",
  QCP_8263_Rev_6_MPQT_FDPT: "QCP 8263 Rev. 6 MPQT/FDPT",
  BLFB1_SSGN_UFR_ITP_BAY_00000_00011_00:
    "BLFB1-SSGN-UFR-ITP-BAY-00000-00011-00",
  BLFB1_SSGN_QAC_ITP_SS7_00000_00001_00:
    "BLFB1-SSGN-QAC-ITP-SS7-00000-00001-00",
  ITP_BPM_22_20_Rev_6: "ITP-BPM-22.20 Rev.6",
  ITP_BPM_22_20_Rev_6_MPQT: "ITP-BPM-22.20 Rev.6 MPQT",
  ITP_011_23_124277_Rev_05: "ITP 011/23/124277 Rev. 05",
  ITP_011_23_124277_Rev_05_MPQT: "ITP 011/23/124277 Rev. 05 MPQT",
  QCP_5513_Rev_2_FDPT: "QCP 5513 Rev. 2 (FDPT)",
  QCP_5513_Rev_2: "QCP 5513 Rev. 2",
  QCP_5982_Rev_4_FDPT: "QCP 5982 Rev. 4 (FDPT)",
  QCP_5982_Rev_4: "QCP 5982 Rev. 4",
  CPW_ITP_24_23_SAWL_Rev_3: "CPW-ITP-24-23-SAWL Rev. 3",
  CPW_ITP_24_23_SAWL_Rev_3_MPQT: "CPW-ITP-24-23-SAWL Rev. 3 MPQT",
  QCP_5987_Rev_2: "QCP 5987 Rev. 2",
  QCP_5987_Rev_2_FDPT: "QCP 5987 Rev. 2 (FDPT)",
  QCP_5983_Rev_5: "QCP 5983 Rev. 5",
  QCP_5983_Rev_5_FDPT: "QCP 5983 Rev. 5 (FDPT)",
  KAR85_AM_K: "KAR85-AM-K",
  QCP_5513: "QCP 5513"
};
